<template>
  <div>
    <div v-if="schedule">
      <b-card>
        <Matrix
          hide-pagination
          :primary-fields="[
            { key: 'primary', label: ''},
            { key: 'due', label: 'A Expirar'},
            { key: 'expired', label: 'Expirado'},
            { key: 'total', label: 'total'},
          ]"
          :items="summaryData"
        />
      </b-card>

      <b-card v-if="!objectIsEmpty(debtorsMatrixItems)">
        <Matrix
          :primary-fields="debtorsMatrixPrimaryFields"
          :items="debtorsMatrixItems"
        />
      </b-card>

      <b-card v-if="!objectIsEmpty(contractHolderMatrixItems)">
        <Matrix
          :primary-fields="contractHolderMatrixPrimaryFields"
          primary-key="payment_scheme"
          secondary-key="contract_holder"
          item-key="total_amount"
          :items="contractHolderMatrixItems"
        />
      </b-card>

      <UR
        v-if="schedule.register === 'TAG'"
        :urs-available="schedule.additional_information.total_due_urs"
        :available-amount="summaryData[2].due"
        :items="ursData"
        :urs-commited="summaryData[0].expired"
        :total="summaryData[0].total"
        :total-available="summaryData[0].due"
        :constituted=" removeSymbolsMoney(summaryData[3].due)"
        :fields="[
          { key: 'expected_settlement_date', label: 'Data da UR', sortable: true },
          { key: 'acquirer', label: 'Adquirente' },
          { key: 'payment_scheme', label: 'Esquema' },
          { key: 'committed_amount', label: 'comprometido', sortable: true },
          { key: 'committed_effect_amount', label: 'autorizado', sortable: true },
          { key: 'uncommitted_amount', label: 'Disponível', sortable: true },
          { key: 'settled_amount', label: 'Liquidado', sortable: true },
          { key: 'total_amount', label: 'Total', sortable: true },
          { key: 'time', label: 'Prazo'},
          { key: 'effect', label: 'Efeito' },
        ]"
      />

      <CercUR
        v-else
        :urs-available="schedule.additional_information.total_due_urs"
        :available-amount="summaryData[2].due"
        :items="ursData"
        :urs-commited="summaryData[0].expired"
        :total="summaryData[0].total"
        :constituted=" removeSymbolsMoney(summaryData[3].due)"
        :total-available="summaryData[0].due"
        :fields="[
          { key: 'expected_settlement_date', label: 'Data da UR', sortable: true },
          { key: 'acquirer', label: 'Adquirente' },
          { key: 'payment_scheme', label: 'Esquema' },
          { key: 'committed_amount', label: 'comprometido', sortable: true },
          { key: 'uncommitted_amount', label: 'Disponível', sortable: true },
          { key: 'settled_amount', label: 'Liquidado', sortable: true },
          { key: 'total_amount', label: 'Total', sortable: true },
          { key: 'time', label: 'Prazo'},
          { key: 'effect', label: 'Efeito' },
        ]"
      />

    </div>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue';

import UR from './URsTable/URsTable.vue';
import CercUR from './CercURsTable/URsTable.vue';
import Matrix from '@/views/components/Matrix.vue';

import value from '@/filters/value';
import WebService from '@/services/osiris/WebService';
import { taxIdMask } from '@/utils/mask';

export default {
    components: {
        BCard,
        Matrix,
        UR,
        CercUR,
    },

    props: {
        schedule: { type: Object, required: true },
    },

    data() {
        return {
            id: this.$route.params.id,
            renderPage: false,
            merchantId: null,
            register: null,
            enumContractHolderName: {},
            enumDebtors: {},
        };
    },

    computed: {
        ursData() {
            const { settlement_expectations: settlementExpectations } = this.schedule;

            if (!settlementExpectations) return [];
            const doEffectAmount = expectation => expectation.settlement_obligations
                .reduce((totalEffect, obligation) => totalEffect + obligation.contract_obligations
                    .reduce((effectAmountTotal, obligationWithEffectAmount) => effectAmountTotal + obligationWithEffectAmount.committed_effect_amount, 0),
                0);

            const mapped = settlementExpectations.map(item => {
                const haveEffect = false;
console.log(item);

                return {
                    ...item,                  
                    effect: item.effect === true ? true : ((item.committed_amount ?? 0) > 0 ? haveEffect : false)                };
            });

            return mapped.sort((a, b) => a.expected_settlement_date.localeCompare(b.expected_settlement_date));
        },

        summaryData() {
            
            if (this.objectIsEmpty(this.schedule.additional_information)) return [];
            const {
                committed_amount: committedAmount,
                total_urs: totalUrs,
                total_due_urs,
                total_expired_urs,
                committed_due_amount: committedDueAmount,
                committed_expired_amount: committedExpiredAmount,
                total_due_amount: totalDueAmount,
                total_expired_amount: totalExpiredAmount,
                total_amount: totalAmount,
                uncommitted_amount: uncommittedAmount,
                uncommitted_due_amount: uncommittedDueAmount,
                uncommitted_expired_amount: uncommittedExpiredAmount,
            } = this.schedule.additional_information;

            const formatThousand = valueToFormat => valueToFormat.toLocaleString('pt-BR', { useGrouping: true });

            return [
                {
                    primary: 'URS',
                    total: formatThousand(totalUrs),
                    due: formatThousand(total_due_urs),
                    expired: formatThousand(total_expired_urs),
                },

                {
                    primary: 'Comprometido',
                    total: value(committedAmount),
                    due: value(committedDueAmount),
                    expired: value(committedExpiredAmount),
                },

                {
                    primary: 'Disponível',
                    total: value(uncommittedAmount),
                    due: value(uncommittedDueAmount),
                    expired: value(uncommittedExpiredAmount),
                },

                {
                    primary: 'Constituído',
                    total: value(totalAmount),
                    due: value(totalDueAmount),
                    expired: value(totalExpiredAmount),
                },
            ];
        },

        debtorsMatrixPrimaryFields() {
            return [
                { key: 'brand', label: 'processadora' },
                { key: 'due_amount', label: 'a expirar' },
                { key: 'expired_amount', label: 'expirado' },
                { key: 'total_amount', label: 'total' },
            ];
        },

        debtorsMatrixItems() {
            return this.schedule.debtors.map(item => ({
                brand: this.enumDebtors[item.debtor],
                total_amount: value(item.total_amount),
                due_amount: value(item.due_amount),
                expired_amount: value(item.expired_amount),
            }));
        },

        contractHolderMatrixPrimaryFields() {
            return [
                { key: 'contract_holder', label: 'Titular do contrato' },
                { key: 'due_amount', label: 'a expirar' },
                { key: 'expired_amount', label: 'expirado' },
                { key: 'total_amount', label: 'total' },
            ];
        },

        contractHolderMatrixItems() {
            return this.schedule.contract_holders.map(item => ({
                contract_holder: this.enumContractHolderName[item.contract_holder],
                total_amount: value(item.total_amount),
                due_amount: value(item.due_amount),
                expired_amount: value(item.expired_amount),
            }));
        },
    },

    mounted() {
        this.doData(this.schedule);
    },

    methods: {
        removeSymbolsMoney(value) {
            const removeSymbolsMoney = value => (typeof value === 'string'
                ? +value.replace(/[R$ .]/g, '').replace(',', '.')
                : value);
            return removeSymbolsMoney(value);
        },
        async getAllTaxIdName(taxIds, params = []) {
            const set = new Set(taxIds);
            const array = Array.from(set);

            const promises = array.map(taxId => WebService.findCNPJ(taxId));

            const fetchedTaxIds = await Promise.all(promises);

            const taxIdsToLegalTaxName = fetchedTaxIds.reduce((acc, response) => {
                const { data, status } = response;

                const taxId = status === 200 ? data.tax_id : response.config.url.split('/')[1];

                const { legal_tax_name: legalTaxName, name } = data;
                const regex = /^[^a-zA-Z]*$/;
                const nameHasLetters = !regex.test(name);

                if(params.includes('tax_id')) {
                    data.tax_id = taxIdMask(data.tax_id)
                }

                const keys = params.map(item => data[item]).join(' ')

                const formatNameWithParams = (value) => keys.length > 0 ? `${value} - ${keys}` : value

                acc[taxId] = (nameHasLetters && formatNameWithParams(name)) || formatNameWithParams(legalTaxName) || taxId;

                return acc;
            }, {});

            return taxIdsToLegalTaxName;
        },

        async doData(data) {
            const allContractHolders = data.contract_holders.map(item => item.contract_holder);
            const brands = data.debtors.map(({ debtor }) => debtor);

            const [contractHoldersName, debtorsName] = await Promise.all([
                this.getAllTaxIdName(allContractHolders),
                this.getAllTaxIdName(brands, ['tax_id']),
            ]);

            this.enumContractHolderName = contractHoldersName;
            this.enumDebtors = debtorsName;

            this.schedule.start_date = this.formatDateToInput(data.start_date);
            this.schedule.end_date = this.formatDateToInput(data.end_date);
        },
    },
};
</script>
