export default class CercMapper {
    // eslint-disable-next-line class-methods-use-this
    mapSettlementExpectations(schedule) {

        return schedule.reduce((acc, item) => {
            const urs = item.receivable_units.map(ur => ({
                expected_settlement_date: ur.liquidation_date,
                acquirer: item.acquirer,
                settled_amount: +ur.settled_amount ?? 0,
                total_amount: +ur.total_amount,
                committed_amount: ur.committed_amount ?? 0,
                payment_scheme: item.payment_scheme,
                uncommitted_amount: Math.max(ur.total_amount - (ur.committed_amount ?? 0), 0),
                effect: ur.committed_amount != null && ur.committed_amount > 0
            }));

            return [...acc, ...urs];
        }, []);
    }

    map(data) {
        return {
            ...data,
            payment_scheme: [],
            register: 'CERC',
            settlement_expectations: this.mapSettlementExpectations(data.schedule),
        };
    }
}
